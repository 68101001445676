// @flow
import React from 'react'
import { graphql } from 'gatsby'

import { withIntl } from 'src/intl'

import { Layout, Footer, Navbar } from 'src/common/components'
import HeadTags from 'src/components/HeadTags'

import LandingPageHeader from 'src/components/ContentRow/LandingPageHeader'
import Testimonials from 'src/components/ContentRow/Testimonials'
import ContentSection from '../components/ContentRow/ContentSection/ContentSection'
import { FAQs } from '../components/FAQs/FAQs'
import { isMobile } from 'react-device-detect'
import { SubjectCarousel } from '../components/SubjectCarousel/SubjectCarousel'
import { splitArrayAtIndex } from '../common/utils'
import QuestionsAnswered from '../components/ContentRow/QuestionsAnswered'
import { IconBackground } from '../components/IconBackground/IconBackground'

type PageContext = {
  locale: string,
  pagePath: string,
}

type Props = {
  data: Object,
  pageTitle: string,
  seoTitle: string,
  metaDescription: string,
  publisherLink: string,
  pageContext: PageContext,
}

function TeacherPage({ data, pageContext }: Props) {
  const {
    meta,
    intro,
    subjects,
    answers,
    contentSections,
    testimonials,
    faqs,
  } = data.markdownRemark.frontmatter

  const { footer } = data.footer.frontmatter
  const asset =
    isMobile && intro.assetLinkMobile ? intro.assetLinkMobile : intro.assetLink
  const [contentSectionsFirst, contentSectionsLast] = splitArrayAtIndex(
    contentSections.list,
    contentSections.numToShowBeforeSplit ?? 0
  )

  return (
    <>
      <HeadTags pageContext={pageContext} {...meta} />
      <Layout>
        {{
          navbar: <Navbar pagePath={pageContext.pagePath} />,
          background: <IconBackground />,
          footer: <Footer content={footer} />,
          body: (
            <>
              <LandingPageHeader
                heading={intro.heading}
                description={intro.description}
                primaryButton={intro.primaryButton}
                secondaryButtons={intro.secondaryButtons?.list}
                asset={asset}
                assetAlt={intro.assetAlt}
              />
              <SubjectCarousel subjects={subjects} />
              {contentSectionsFirst.map(contentSection => (
                <ContentSection
                  content={contentSection}
                  key={contentSection.heading}
                />
              ))}
              <QuestionsAnswered content={answers} />
              {contentSectionsLast.map(contentSection => (
                <ContentSection
                  content={contentSection}
                  key={contentSection.heading}
                />
              ))}
              <Testimonials content={testimonials} />
              <FAQs faqs={faqs} />
            </>
          ),
        }}
      </Layout>
    </>
  )
}

export default withIntl(TeacherPage)

export const TeachersPageQuery = graphql`
  query TeachersQuery($id: String!, $locale: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        ...MetaFragment
        ...LandingIntroFragment
        ...LandingSubjectsFragment
        ...LandingContentSectionsFragment
        ...LandingAnswersFragment
        testimonials {
          heading
          image
          list {
            image
            emoji
            testimonial
            author
            position
          }
        }
        ...LandingFAQsFragment
      }
    }
    ...FooterFragment
  }
`
